import {
  BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate
} from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import About from "./Pages/About";
import Contactus from "./Pages/Contactus";
import Brandlogin from "./Pages/Auth/Brandlogin";
import Brandregister from "./Pages/Auth/Brandregister";
import Creatorregister from "./Pages/Auth/Creatorregister";
import Yolo from "./Pages/Yolo";
import Support from "./Pages/Support";
import DashboardLayout from "./components/DashboardLayout";
import DashboardHome from "./Pages/Dashboard/DashboardHome";
import Profile from "./Pages/Dashboard/Profile";
import Settings from "./Pages/Dashboard/Settings";
import DashSupport from "./Pages/Dashboard/UserSupport";
import MyEarnings from "./Pages/Dashboard/MyEarnings";
import Creator_FAQ from "./Pages/Dashboard/Creator_FAQ";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import { UserProvider } from "./Pages/Auth/UserContext";
import axios from "axios";
import { useState, useEffect } from "react";
import { API_URL } from "./constant/constant";
import { PuffLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminRedirect() {
  window.location.replace("https://admindashboard.bookmesocial.com");
  return null;
}

const useIsDashboard = () => {
  const { pathname } = useLocation();
  return pathname.startsWith("/dashboard");
};

function ProtectedRoute({ children }) {
  const [isAuth, setIsAuth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/auth/check`,
          {
            params: { role: 'creator' },
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.authenticated && response.data.role === "creator") {
          setIsAuth(true);
        } else {
          setIsAuth(false);
          navigate("/brandlogin");
        }
      } catch (error) {
        console.error("Auth check error:", error);
        setIsAuth(false);
        navigate("/brandlogin");
      }
    };
    checkAuth();
  }, [navigate]);

  if (isAuth === null) {
    return (
      <div className="loader-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <PuffLoader color="#4A90E2" size={100} />
      </div>
    );
  }
  return children;
};

function App() {
  useEffect(() => {
    toast.dismiss();
    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Content />
      </Router>
    </>
  );
}

function Content() {
  const isDashboard = useIsDashboard();

  return (
    <>
      {!isDashboard && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/brandlogin" element={<Brandlogin />} />
        <Route path="/brandregister" element={<Brandregister />} />
        <Route path="/creatorregister" element={<Creatorregister />} />
        <Route path="/support" element={<Support />} />
        <Route path="/admin" element={<AdminRedirect />} />
        <Route path="/yolastarz" element={<Yolo />} />
        <Route
          path="forgot-password"
          element={
            <UserProvider>
              <ForgotPassword />
            </UserProvider>
          }
        />

        {/* Dashboard Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <UserProvider>
                <DashboardLayout />
              </UserProvider>
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="/dashboard/home" />} />

          <Route path="home" element={<DashboardHome />} />
          <Route path="profile" element={<Profile />} />
          <Route path="earnings" element={<MyEarnings />} />
          <Route path="support" element={<DashSupport />} />
          <Route path="settings" element={<Settings />} />
          <Route path="creator-faq" element={<Creator_FAQ />} />
        </Route>
      </Routes>
      {!isDashboard && <Footer />}
    </>
  );
}

export default App;
