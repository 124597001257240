import React from 'react';
import Support from '../Support';

export default function UserSupport() {
    return (
        <div className="text-gray-700">
            <Support />
            <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-center">
                Support & Documents
            </h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-4 px-2 md:px-0">
                <a
                    href="/docs/Privacy_Policy.pdf"
                    className="bg-blue-500 hover:bg-blue-700 text-white duration-300 ease-in-out 
                    py-2 md:py-3 px-3 md:px-4 rounded-lg shadow-md text-center text-sm md:text-base"
                    target='_blank'
                >
                    Privacy Policy
                </a>
                <a
                    href="/docs/Terms_and_Conditions.pdf"
                    className="bg-green-500 hover:bg-green-700 text-white duration-300 ease-in-out 
                    py-2 md:py-3 px-3 md:px-4 rounded-lg shadow-md text-center text-sm md:text-base"
                    target='_blank'
                >
                    Terms and Conditions
                </a>
                <a
                    href="/docs/Brands_Policy.pdf"
                    className="bg-yellow-500 hover:bg-yellow-700 text-white duration-300 ease-in-out 
                    py-2 md:py-3 px-3 md:px-4 rounded-lg shadow-md text-center text-sm md:text-base"
                    target='_blank'
                >
                    Brands Policy
                </a>
                <a
                    href="/docs/Partners_Terms.pdf"
                    className="bg-red-500 hover:bg-red-700 text-white duration-300 ease-in-out 
                    py-2 md:py-3 px-3 md:px-4 rounded-lg shadow-md text-center text-sm md:text-base"
                    target='_blank'
                >
                    Partners Policy
                </a>
            </div>
        </div>
    );
}