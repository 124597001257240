import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../constant/constant';
import { PuffLoader } from 'react-spinners';
import { FaEnvelope, FaKey, FaLock } from 'react-icons/fa';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendOtp = async () => {
        if (!email) {
            toast.error('Please enter your email.', {
                toastId: 'email-required'
            });
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(
                `${API_URL}/api/auth/send-otp`,
                {
                    email: email,
                    type: 'forgot-password'
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                toast.success('OTP sent successfully!', {
                    toastId: 'otp-sent'
                });
                setStep(2);
            }
        } catch (error) {
            console.error('Send OTP error:', error);
            const errorMessage = error.response?.data?.error || 'Error sending OTP. Please try again.';
            toast.error(errorMessage, {
                toastId: 'otp-error'
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle verifying OTP and updating the password
    const handleVerifyOtpAndChangePassword = async () => {
        if (!otp || !newPassword) {
            toast.error('Please fill out all fields.', {
                toastId: 'fields-required'
            });
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(
                `${API_URL}/api/auth/reset-password`,
                {
                    email: email,
                    otp: otp,
                    newPassword: newPassword
                },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                toast.success('Password changed successfully!', {
                    toastId: 'password-changed'
                });
                setTimeout(() => {
                    navigate('/brandlogin');
                }, 2000);
            }
        } catch (error) {
            console.error('Reset password error:', error);
            if (error.response?.data?.error === "OTP has expired") {
                toast.error('OTP has expired. Please request a new one.', {
                    toastId: 'otp-expired'
                });
                setStep(1);
            } else {
                toast.error(error.response?.data?.error || 'Failed to change password', {
                    toastId: 'password-change-error'
                });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="flex items-center justify-center min-h-screen bg-gray-100 px-4 py-8">
            <div className="w-full max-w-md p-6 bg-white shadow-lg rounded-lg">
                <ToastContainer autoClose={2000} />
                <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center text-gray-800">
                    {step === 1 ? 'Forgot Password' : 'Enter OTP and New Password'}
                </h2>

                {step === 1 && (
                    <div className="space-y-4">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FaEnvelope className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 outline-none text-gray-600"
                            />
                        </div>

                        <button
                            onClick={handleSendOtp}
                            disabled={loading}
                            className="w-full flex items-center justify-center gap-2 bg-indigo-600 hover:bg-indigo-700 text-white py-3 px-4 rounded-lg transition-colors duration-300"
                        >
                            {loading ? (
                                <PuffLoader color="#ffffff" size={24} />
                            ) : (
                                <>
                                    <FaEnvelope className="h-5 w-5" />
                                    <span>Send OTP</span>
                                </>
                            )}
                        </button>
                    </div>
                )}

                {step === 2 && (
                    <div className="space-y-4">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FaKey className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                id="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter the OTP sent to your email"
                                className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 outline-none text-gray-600"
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <FaLock className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="password"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter your new password"
                                className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 outline-none text-gray-600"
                            />
                        </div>

                        <button
                            onClick={handleVerifyOtpAndChangePassword}
                            disabled={loading}
                            className="w-full flex items-center justify-center gap-2 bg-indigo-600 hover:bg-indigo-700 text-white py-3 px-4 rounded-lg transition-colors duration-300"
                        >
                            {loading ? (
                                <PuffLoader color="#ffffff" size={24} />
                            ) : (
                                <>
                                    <FaLock className="h-5 w-5" />
                                    <span>Change Password</span>
                                </>
                            )}
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ForgotPassword;
