import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Outlet, NavLink, Link } from "react-router-dom";
import logo from "../assets/logo (53).png";
import { FaBars, FaTimes, FaHome, FaUser, FaMoneyBill, FaHeadset, FaCog, FaBell, FaQuestionCircle } from "react-icons/fa";
import { API_URL } from "../constant/constant";
import { toast } from "react-toastify";

const DashboardLayout = () => {
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMessagePanelOpen, setIsMessagePanelOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        fetchMessages();

        const interval = setInterval(fetchMessages, 5000);

        return () => clearInterval(interval);
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/messages/creator-messages/all`);
            setMessages(response.data.messages);
            setUnreadCount(response.data.unreadCount);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const markMessageAsRead = async (messageId) => {
        try {
            const response = await axios.put(`${API_URL}/api/messages/mark-read/${messageId}`);
            if (response.data.success) {
                setMessages(response.data.messages);
                setUnreadCount(response.data.unreadCount);
            }
        } catch (error) {
            console.error('Error marking message as read:', error);
        }
    };

    const handleMessageButtonClick = async () => {
        try {
            // Mark all unread messages as read
            const unreadMessages = messages.filter(msg => !msg.isRead);
            for (const msg of unreadMessages) {
                await markMessageAsRead(msg._id);
            }

            // Open message panel
            setIsMessagePanelOpen(true);
        } catch (error) {
            console.error('Error marking messages as read:', error);
        }
    };

    const handleLogout = async () => {
        try {
            // Call the logout endpoint
            const response = await axios.get(`${API_URL}/api/creatorreg/creator_logout`, {
                withCredentials: true
            });

            // Clear the cookie from all possible domains
            const domains = [
                '.bookmesocial.com',
                'bookmesocial.com',
                'localhost',
                window.location.hostname
            ];

            domains.forEach(domain => {
                document.cookie = `creator_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}; secure; samesite=none`;
            });

            // Clear any local storage items if you have any
            localStorage.removeItem('user');

            // Show success message
            toast.success(response.data.message, {
                toastId: 'logout-success'
            });

            // Add a small delay before navigation
            await new Promise(resolve => setTimeout(resolve, 500));

            // Force a full page reload to clear any cached states
            window.location.href = '/brandlogin';
        } catch (error) {
            console.error('Logout error:', error);
            toast.error(error.response?.data?.message || "Logout failed", {
                toastId: 'logout-error'
            });
        }
    };

    const handleContactAdmin = (isMobile = false) => {
        const adminEmail = 'admin@bookmesocial.com';
        const subject = 'Creator Support Request';
        const body = 'Hello Admin,\n\nI need assistance with...';

        if (isMobile) {
            const mailtoLink = `mailto:${adminEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
        } else {
            const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${adminEmail}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.open(gmailComposeUrl, '_blank');
        }
    };

    const MessageButton = () => (
        <button
            onClick={handleMessageButtonClick}
            className="relative bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
            Message
            {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                    {unreadCount}
                </span>
            )}
        </button>
    );

    return (
        <div className="flex h-screen bg-gray-300 p-2">
            {/* Mobile Header */}
            <div className="md:hidden fixed top-0 left-0 right-0 bg-white shadow-md p-4 flex justify-between items-center z-50">
                <img className="h-8 w-auto" src={logo} alt="Your Company" />
                <button
                    className="text-black"
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                    {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
            </div>

            {/* Main Container */}
            <div className="flex w-full h-full max-w-full bg-white shadow-lg rounded-xl overflow-hidden m-auto">
                {/* Sidebar */}
                <div className={`
                    bg-indigo-600 
                    md:w-64 md:block
                    ${isMobileMenuOpen ? 'absolute w-52 left-0 h-full' : 'hidden'}
                    md:relative transition-all duration-300 ease-in-out
                    z-40 md:z-0
                `}>
                    <div className="h-full p-5 overflow-y-auto">
                        {/* Sidebar Content */}
                        <div className="mb-6">
                            <NavLink to="/">
                                <img className="hidden md:block h-10 w-auto mx-auto" src={logo} alt="Your Company" />
                            </NavLink>
                        </div>
                        <hr className="border-t border-indigo-400 mb-6" />
                        <ul>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/dashboard/home"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaHome className="mr-2" />Dashboard
                                </NavLink>
                            </li>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/dashboard/profile"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaUser className="mr-2" />
                                    Profile
                                </NavLink>
                            </li>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/dashboard/earnings"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaMoneyBill className="mr-2" />
                                    Earnings
                                </NavLink>
                            </li>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/dashboard/support"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaHeadset className="mr-2" />
                                    Support
                                </NavLink>
                            </li>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    to="/dashboard/settings"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaCog className="mr-2" />
                                    Settings
                                </NavLink>
                            </li>
                            <li className="mb-3 md:text-xl text-base">
                                <NavLink
                                    // to="/dashboard/settings"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaBell className="mr-2" />
                                    Notifications
                                </NavLink>
                            </li>
                            <li className="mb-2 md:text-xl text-base">
                                <NavLink
                                    // to="/dashboard/settings"
                                    className={({ isActive }) =>
                                        isActive ? "bg-indigo-500 p-3 rounded-md flex items-center text-white" : "hover:bg-indigo-500 p-3 rounded-md flex items-center text-white"
                                    }
                                >
                                    <FaQuestionCircle className="mr-2" />
                                    {/* Request - Social */}
                                </NavLink>
                            </li>
                        </ul>

                        {/* Mobile-only buttons */}
                        <div className="md:hidden mt-6 space-y-2">
                            <button
                                onClick={handleMessageButtonClick}
                                className="relative bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 w-full rounded"
                            >
                                Message
                                {unreadCount > 0 && (
                                    <span className="absolute -top-2 -right-2 bg-blue-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
                                        {unreadCount}
                                    </span>
                                )}
                            </button>
                            <button
                                onClick={() => handleContactAdmin(true)}
                                className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full"
                            >
                                Contact Administrator
                            </button>
                            <Link to="/dashboard/creator-faq" className="block w-full">
                                <button className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full">
                                    Creator FAQ
                                </button>
                            </Link>
                            <button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-700 text-white text-[14px] py-1.5 px-3 rounded w-full"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </div>

                {/* Main Content Area */}
                <div className="flex-1 flex flex-col min-h-0 md:pl-0 pt-16 md:pt-0">
                    {/* Desktop Header */}
                    <header className="hidden md:flex w-full bg-indigo-700 text-white justify-between items-center p-4 mb-6 rounded-md">
                        <div className="text-xl font-bold mr-2">Dashboard</div>
                        <div className="flex items-center space-x-4">
                            <MessageButton />
                            <button
                                onClick={() => handleContactAdmin(false)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Contact Administrator
                            </button>
                            <Link to="/dashboard/creator-faq">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                    Creator FAQ
                                </button>
                            </Link>
                            <button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Logout
                            </button>
                        </div>
                    </header>

                    {/* Main Content */}
                    <main className="flex-1 overflow-y-auto bg-white p-4 shadow rounded-md">
                        <Outlet />
                    </main>
                </div>
            </div>

            {/* Mobile Overlay */}
            {isMobileMenuOpen && (
                <div
                    className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}

            {isMessagePanelOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg w-full max-w-lg max-h-[90vh] flex flex-col">
                        {/* Header */}
                        <div className="flex justify-between items-center p-4 border-b">
                            <h2 className="text-xl font-bold text-gray-800">Messages</h2>
                            <button
                                onClick={() => setIsMessagePanelOpen(false)}
                                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            >
                                <FaTimes />
                            </button>
                        </div>

                        {/* Messages Container */}
                        <div className="flex-1 overflow-y-auto p-4">
                            {messages.length === 0 ? (
                                <div className="flex items-center justify-center h-full">
                                    <p className="text-gray-500 text-center">No messages</p>
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    {messages.map((msg) => (
                                        <div
                                            key={msg._id}
                                            className={`p-4 border rounded-lg shadow-sm
                                                ${!msg.isRead ? 'bg-blue-50 border-blue-100' : 'bg-white border-gray-200'}`}
                                            onClick={() => markMessageAsRead(msg._id)}
                                        >
                                            <div className="font-medium text-gray-800">{msg.subject}</div>
                                            <div className="text-gray-600 mt-2 text-sm whitespace-pre-wrap">
                                                {msg.message}
                                            </div>
                                            <div className="text-xs text-gray-400 mt-2 flex items-center">
                                                <span>{new Date(msg.createdAt).toLocaleDateString()}</span>
                                                <span className="mx-2">•</span>
                                                <span>
                                                    {new Date(msg.createdAt).toLocaleTimeString([], {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardLayout;