import React, { useState } from 'react';
import Accordion from '../../components/Accordion';
import { Fade } from "react-awesome-reveal";

export default function Creator_FAQ() {
    const [accordions, setAccordion] = useState([
        {
            key: 1,
            title: 'What is Bookmesocial.com ?',
            data: `Bookmesocial.com influencer marketing agency that connects brands with Influencers and Creators for brand Collaborations, Campaigns,and Partnerships. We help you monetize your content by connecting you with Brands that align with your audience.`,
            isOpen: false,
        },
        {
            key: 2,
            title: 'Do I need a Certain number of followers to join ?',
            data: `While larger followings can lead to more opportunities, we welcome influencers with engaged audiences of all sizes from Nano to Mega-Influencers. The quality of your content and engagement is more important than follower count alone. Its Purely based on the campaigns to campaign.`,
            isOpen: false,
        },
        {
            key: 3,
            title: 'How do I join your influencer network ?',
            data: `You can sign up on our platform by visiting website.Fill out the necessary details,connect your social media accounts, and our team will review your profile. Once approved,you will be eligible for collaborations with top brands`,
            isOpen: false,
        },
        {
            key: 4,
            title: 'Have Bookmesocial.com Fix any campaigns ?',
            data: `No, we don't fix or create any campaigns, Its purely based on brand and advertisers. If you need any specific campaign kindly write us at admin@bookmesocial.com`,
            isOpen: false,
        },
        {
            key: 5,
            title: 'What types of Campaigns do you offer ?',
            data: `We offer a range of campaign options to meet brand goals,our team works closely with you to develop customized campaigns that resonate with your target audience and drive results.`,
            isOpen: false,
        },
        {
            key: 6,
            title: 'What kind of content do I need to create for brand Collaborations ?',
            data: `The content type will vary depending on the brand and the campaign. Most collaborations involve creating social media posts, stories, reels, or blog content that aligns with the brand's goals. Clear guidelines and deliverables will be provided for each campaign.`,
            isOpen: false,
        },
        {
            key: 7,
            title: 'Do you offer long-term partnerships with brands ?',
            data: `Yes, we do. If a brand finds your content and audience to be a great match, there is potential for long-term partnerships or ambassador programs where you represent the brand over an extended period.`,
            isOpen: false,
        },
        {
            key: 8,
            title: 'How do I get to know about Campaigns ?',
            data: `Influencers will get campaign proposals directly from our end,provided they have already signed up with us. They must fill up all the mandatory fields to get notification from us.`,
            isOpen: false,
        },
        {
            key: 9,
            title: 'When will I get my campaign ?',
            data: `Influencers need to Sign up with us to know more about campaigns. After signing up, our team will check every profile to understand their areas of interest. On the amount basis of that, we will send them campaign proposals over time.`,
            isOpen: false,
        },
        {
            key: 10,
            title: 'How much can I earn from brand collaborations ?',
            data: `Earnings depend on factors like your follower Count,Engagement Rate,Content quality and the scope of the campaign. We work to negotiate fair compensation for all Influencers based on these factors.`,
            isOpen: false,
        },
        {
            key: 11,
            title: 'How do I transfer money to my bank?',
            data: `Bookmesocial.com has the lowest withdrawal threshold of ₹95, across the industry! As soon as you earn above ₹95 or above Confirmed Profit, you can simply transfer your Profit into your Bank Account its for Influencer tieups. In Affiliate Marketing read the description before apply for the campaigns for payment and all details.`,
            isOpen: false,
        },
        {
            key: 12,
            title: 'Do you charge Fee?',
            data: `Yes, we do, but there is no charge for consulting or discussing your project with our team. Once we start working then depending on the project type and size we will charge a nominal Rs.192/- to Rs.499/- fee only.Based on the campaign.Some campaign values below Rs.2000/- we don't charge any amounts.`,
            isOpen: false,
        },
        {
            key: 13,
            title: 'When will I get paid as a Partner & mode of Payment?',
            data: `Influencers/Creators will receive the payment after the successful completion of their campaign assigned by us (Bookmesocial.com).You will receive your payment within [7 - 21] days after the content delivery.We offer multiple payment methods NEFT and Bank Transfer only. In Affiliates read the description before apply for the campaigns clearly for the payment details.`,
            isOpen: false,
        },
        {
            key: 14,
            title: 'How do I contact support if I have issues?',
            data: `If you have any questions or issues, you can reach out to our support team at [admin@bookmesocial.com]. We are here to help you through every step of the collaboration process.`,
            isOpen: false,
        },
    ]);

    const toggleAccordion = accordionkey => {
        const updatedAccordions = accordions.map(accord => {
            if (accord.key === accordionkey) {
                return { ...accord, isOpen: !accord.isOpen };
            } else {
                return { ...accord, isOpen: false };
            }
        });

        setAccordion(updatedAccordions);
    };

    return (
        <section className="relative px-2 md:px-6 py-4 md:py-6 max-w-full">
            <Fade cascade>
                <div className="max-w-6xl mx-auto">
                    {/* Header */}
                    <div className="mb-6 md:mb-8">
                        <h3 className="text-xl md:text-3xl lg:text-4xl font-semibold text-center text-gray-800">
                            Frequently Asked Questions
                        </h3>
                    </div>

                    {/* FAQ Container */}
                    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                        <div className="space-y-2">
                            {accordions.map(accordion => (
                                <Accordion
                                    key={accordion.key}
                                    title={accordion.title}
                                    data={accordion.data}
                                    isOpen={accordion.isOpen}
                                    toggleAccordion={() => toggleAccordion(accordion.key)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Fade>
        </section>
    );
}
