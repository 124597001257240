import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import PuffLoader from 'react-spinners/PuffLoader';
import img1 from "../../assets/auth/partner_bg.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { API_URL } from "../../constant/constant";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { FaUserPlus, FaFacebookF, FaPaperPlane } from 'react-icons/fa';

axios.defaults.withCredentials = true;

const GoogleLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
  </svg>
);

export default function Creatorregister() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email_id: "",
    password_creator: "",
    confirm_password: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    if (!otp) {
      toast.error("Please enter OTP", {
        toastId: 'otp-required'
      });
      return;
    }

    try {
      setIsVerifyingOtp(true);

      const response = await axios.post(
        `${API_URL}/api/auth/verify-otp`,
        {
          email_id: formData.email_id,
          otp: otp
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        await new Promise(resolve => setTimeout(resolve, 1000));

        toast.success("Email verified successfully!", {
          toastId: 'otp-verified'
        });
        setOtpVerified(true);
      }
    } catch (error) {
      console.error('Verification error:', error);
      toast.error(error.response?.data?.error || "Invalid OTP", {
        toastId: 'otp-verify-error'
      });
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otpVerified) {
      toast.error("Please verify OTP first", {
        toastId: 'verify-otp-required'
      });
      return;
    }

    if (!isChecked) {
      toast.error("Please accept Terms & Conditions", {
        toastId: 'terms-required'
      });
      return;
    }

    try {
      setIsSubmitting(true);

      const loginResponse = await axios.post(
        `${API_URL}/api/creatorreg/creator_login`,
        {
          email_id: formData.email_id,
          password_creator: formData.password_creator
        },
        { withCredentials: true }
      );

      if (loginResponse.status === 200) {
        toast.success("Registration completed successfully!", {
          toastId: 'registration-success'
        });

        setTimeout(() => {
          navigate("/dashboard");
        }, 1500);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to complete registration", {
        toastId: 'registration-failed'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.mobile_number || !formData.email_id || !formData.password_creator || !formData.confirm_password) {
      toast.error("Please fill all required fields", {
        toastId: 'fields-required'
      });
      return;
    }

    if (formData.password_creator !== formData.confirm_password) {
      toast.error("Passwords do not match", {
        toastId: 'password-mismatch'
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email_id)) {
      toast.error("Please enter a valid email address", {
        toastId: 'invalid-email'
      });
      return;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.mobile_number)) {
      toast.error("Please enter a valid 10-digit mobile number", {
        toastId: 'invalid-phone'
      });
      return;
    }

    try {
      setIsSendingOtp(true);

      const checkEmailResponse = await axios.post(
        `${API_URL}/api/auth/check-email`,
        { email: formData.email_id }
      );

      if (checkEmailResponse.data.exists) {
        toast.error("Email already registered", {
          toastId: 'email-exists'
        });
        return;
      }

      const registerResponse = await axios.post(
        `${API_URL}/api/creatorreg/Register_cre_influ`,
        {
          name: formData.name,
          mobile_number: formData.mobile_number,
          email_id: formData.email_id,
          password_creator: formData.password_creator,
          is_verified: false
        }
      );

      if (registerResponse.status === 200) {
        const otpResponse = await axios.post(
          `${API_URL}/api/auth/send-otp`,
          {
            email: formData.email_id,
            type: 'registration'
          }
        );

        if (otpResponse.status === 200) {
          toast.success("OTP sent successfully!", {
            toastId: 'otp-sent'
          });
          setOtpSent(true);
        }
      }
    } catch (error) {
      let errorMessage = "Failed to process request";
      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }

      toast.error(errorMessage, {
        toastId: 'request-failed'
      });
    } finally {
      setIsSendingOtp(false);
    }
  };

  return (
    <div>
      <section className="relative flex justify-center w-full py-24 overflow-hidden bg-gradient-to-b to-transparent from-indigo-600/20">
        <Fade cascade>
          <div className="container relative">
            <div className="relative grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
              <div className="md:col-span-6">
                <div className="md:me-8">
                  <img src={img1} alt="" />
                  <h2 className="font-bold text-center lg:leading-normal leading-normal text-3xl lg:text-4xl mb-5 text-black relative">
                    Smarter way to generate Revenue
                  </h2>
                  <p className="text-slate-400 text-lg max-w-xl">
                    Are you struggling to generate revenue for your accounts? We
                    help you with generating revenue based on your audience
                    count and audience type. All you need is to be a Creator
                    with us.
                  </p>
                </div>
              </div>
              <div className="md:col-span-6">
                <div className="relative border-[3px] border-gray-100 rounded-lg p-6 bg-white">
                  <h3 className="font-bold text-2xl text-center mb-6">
                    Sign Up as Creator/Influencer
                  </h3>

                  <form className="space-y-4">
                    <input
                      type="text"
                      placeholder="Full Name*"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full p-3 border border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none"
                    />
                    <input
                      type="tel"
                      placeholder="Mobile Number*"
                      value={formData.mobile_number}
                      onChange={(e) => setFormData({ ...formData, mobile_number: e.target.value })}
                      className="w-full p-3 border border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none"
                    />

                    <div className="space-y-2">
                      <div className="flex flex-col sm:flex-row gap-2">
                        <input
                          type="email"
                          placeholder="Email ID*"
                          value={formData.email_id}
                          onChange={(e) => setFormData({ ...formData, email_id: e.target.value })}
                          className="w-full p-3 border border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none"
                        />
                        <button
                          onClick={handleSendOtp}
                          disabled={isSendingOtp || otpVerified}
                          className="whitespace-nowrap px-6 py-3 font-medium text-indigo-600 hover:text-indigo-700 disabled:text-gray-400 bg-transparent border border-indigo-600 hover:bg-indigo-600 hover:text-white disabled:border-gray-400 rounded-lg transition-colors duration-200 sm:w-auto w-full flex items-center justify-center gap-2"
                        >
                          {isSendingOtp ? (
                            <PuffLoader color="#4F46E5" size={20} />
                          ) : (
                            <>
                              <FaPaperPlane className="h-4 w-4" />
                              <span>Send OTP</span>
                            </>
                          )}
                        </button>
                      </div>

                      {otpSent && (
                        <div className="flex flex-col sm:flex-row gap-2">
                          <input
                            type="text"
                            placeholder="Enter OTP*"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="w-full p-3 border border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none"
                          />
                          <button
                            onClick={handleVerifyOtp}
                            disabled={isVerifyingOtp || otpVerified}
                            className="whitespace-nowrap px-6 py-3 text-sm font-medium text-indigo-600 hover:text-indigo-700 disabled:text-gray-400 bg-transparent border border-indigo-600 hover:border-indigo-700 disabled:border-gray-400 rounded-lg transition-colors duration-200 sm:w-auto w-full"
                          >
                            {isVerifyingOtp ? <PuffLoader color="#4F46E5" size={20} /> : "Verify OTP"}
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password*"
                        value={formData.password_creator}
                        onChange={(e) => setFormData({ ...formData, password_creator: e.target.value })}
                        className="w-full p-3 border border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none pr-10"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                      >
                        {showPassword ? <FiEyeOff size={18} /> : <FiEye size={18} />}
                      </button>
                    </div>

                    <input
                      type="password"
                      placeholder="Confirm Password*"
                      value={formData.confirm_password}
                      onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })}
                      className="w-full p-3 border border-gray-200 rounded-lg focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 outline-none"
                    />

                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        className="w-4 h-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <label className="text-sm text-gray-600">
                        I accept the{" "}
                        <a
                          href="/docs/terms-and-conditions.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-indigo-800 underline"
                        >
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="/docs/privacy-policy.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-indigo-600 hover:text-indigo-800 underline"
                        >
                          Privacy Policy
                        </a>
                      </label>
                    </div>

                    <button
                      onClick={handleSubmit}
                      disabled={isSubmitting || !isChecked || !otpVerified}
                      className="w-full p-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:bg-gray-400 disabled:cursor-not-allowed font-medium transition-colors duration-200 flex items-center justify-center gap-2"
                    >
                      {isSubmitting ? (
                        <PuffLoader color="#ffffff" size={24} />
                      ) : (
                        <>
                          <FaUserPlus className="h-5 w-5" />
                          <span>Sign Up</span>
                        </>
                      )}
                    </button>

                    <div className="space-y-3 mt-6">
                      <button
                        type="button"
                        className="w-full p-3 flex items-center justify-center gap-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                      >
                        <GoogleLogo />
                        <span className="text-gray-700 text-base md:text-base">Sign up with Google</span>
                      </button>

                      <button
                        type="button"
                        className="w-full p-3 flex items-center justify-center gap-3 border rounded-lg hover:bg-gray-50"
                      >
                        <FaFacebookF className="text-lg" />
                        <span className="text-base md:text-base">Sign up with Facebook</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
      <ToastContainer autoClose={2000} />
    </div>
  );
}