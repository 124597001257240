export default function Accordion({ title, data, isOpen, toggleAccordion }) {
    return (
        <div className="border rounded-md mb-1 bg-white overflow-hidden">
            <button
                className="w-full flex justify-between items-center p-4 font-medium text-start text-black hover:bg-gray-100 transition duration-300"
                onClick={toggleAccordion}
            >
                <span className="text-sm md:text-base pr-8">{title}</span>
                <span className={`transform ${isOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-300 flex-shrink-0`}>
                    &#9660;
                </span>
            </button>
            {isOpen && (
                <div className="p-4 bg-white">
                    <p className="text-sm md:text-base text-gray-600 whitespace-normal break-words">
                        {data}
                    </p>
                </div>
            )}
        </div>
    );
}