import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import { API_URL } from '../../constant/constant';
import { ToastContainer, toast } from "react-toastify";
import CampaignDetailsModal from '../../components/CampaignDetailsModal';

export default function DashboardHome() {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/campaign/get_all_campain`);
                const openCampaigns = response.data.filter(campaign => campaign.status === 'open');
                setCampaigns(openCampaigns || []);
            } catch (error) {
                toast.error('Error fetching campaigns');
                console.error('Error fetching campaigns:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCampaigns();
    }, []);

    if (loading) return <p>Loading Campaigns...</p>;

    const openModal = (campaign) => {
        setSelectedCampaign(campaign);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCampaign(null);
        setIsModalOpen(false);
    };

    return (
        <div className="text-gray-700">
            <ToastContainer />
            <Fade cascade>
                <h1 className="text-2xl md:text-3xl font-bold mb-2 md:mb-4">Dashboard Home</h1>
                <p className="mb-4 md:mb-6 text-base md:text-lg">Welcome to the Dashboard! Here are the latest campaigns:</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                    {campaigns && campaigns.length > 0 ? (
                        campaigns.map((campaign) => (
                            <div key={campaign._id} className="bg-white p-3 md:p-4 shadow-md rounded-lg flex flex-col justify-between">
                                <div className='w-full h-32 md:h-40 flex items-center justify-center'>
                                    <img
                                        src={campaign.imageUrl}
                                        alt={campaign.name}
                                        className="w-full h-full object-contain rounded-t-lg"
                                    />
                                </div>
                                <div>
                                    <h2 className="text-lg md:text-xl font-bold mt-3 md:mt-4 text-center">{campaign.name}</h2>
                                    <p className="text-gray-700 font-semibold uppercase text-xs md:text-sm tracking-wide mt-2 md:mt-3">
                                        {campaign.brandname}
                                    </p>
                                    <p className="mt-2 md:mt-3 text-gray-500 text-sm md:text-base italic truncate">
                                        {campaign.description}
                                    </p>
                                </div>
                                <button
                                    className='bg-indigo-600 text-white text-sm md:text-base mt-3 md:mt-4 px-3 md:px-4 py-2 rounded w-full'
                                    onClick={() => openModal(campaign)}
                                >
                                    View & Apply
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="text-base md:text-lg">No campaigns available.</p>
                    )}
                    <CampaignDetailsModal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        campaign={selectedCampaign}
                    />
                </div>
            </Fade>
        </div>
    );
}