import { useContext, useState } from 'react';
import { UserContext } from '../Pages/Auth/UserContext';
import Modal from 'react-modal';
import { FaCheck, FaClock } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import axios from 'axios';
import { API_URL } from '../constant/constant';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const CampaignDetailsModal = ({ isOpen, onRequestClose, campaign }) => {
    const { user, loading } = useContext(UserContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleInterestedClick = async (campaignId) => {
        if (loading || isSubmitting) return;

        try {
            setIsSubmitting(true);

            const response = await axios.post(
                `${API_URL}/api/campaign/interested`,
                { campaignId },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                toast.success("Interest added successfully!", { autoClose: 3000 });
                onRequestClose();
            }
        } catch (error) {
            console.error('Error adding interest:', error);
            const errorMessage = error.response?.data?.message || "Failed to register interest.";

            // Handle specific error cases
            if (error.response?.status === 401) {
                toast.error("Please login to show interest", { autoClose: 3000 });
                onRequestClose();
                navigate('/brandlogin');
            } else if (error.response?.status === 400 && error.response.data.message === "Interest already added") {
                toast.info("You have already shown interest in this campaign", { autoClose: 3000 });
            } else {
                toast.error(errorMessage, { autoClose: 3000 });
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!campaign) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Campaign Details"
            className="fixed inset-0 flex items-center justify-center z-[100] mx-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-[99]"
        >
            <div className="bg-white rounded-lg shadow-lg w-full max-w-[95%] md:max-w-[85%] lg:max-w-[800px] relative md:ml-0 md:mr-0">
                <button
                    onClick={onRequestClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 z-10"
                >
                    <IoIosClose className="text-3xl" />
                </button>
                <div className="p-4 md:p-6 lg:p-8 max-h-[90vh] overflow-y-auto">
                    <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold mb-4 md:mb-6 pr-8 text-center">
                        {campaign.name}
                    </h2>
                    {campaign.imageUrl && (
                        <div className="flex justify-center mb-4 md:mb-6">
                            <img
                                src={campaign.imageUrl}
                                alt={campaign.name}
                                className="w-full h-auto max-h-[200px] md:max-h-[300px] lg:max-h-[400px] object-contain rounded-lg"
                            />
                        </div>
                    )}
                    <div className="space-y-4">
                        <p className="text-gray-700 text-md">
                            <strong>Brand Name:</strong> {campaign.brandname}
                        </p>
                        <p className="text-gray-600 text-md">
                            <strong>Description:</strong> {campaign.description}
                        </p>
                    </div>
                    <div className="mt-6 md:mt-8 flex flex-col md:flex-row justify-center items-stretch md:items-center gap-3 md:gap-6">
                        <button
                            onClick={() => handleInterestedClick(campaign._id)}
                            disabled={isSubmitting || loading}
                            className={`flex items-center justify-center ${isSubmitting || loading
                                ? 'bg-gray-400'
                                : 'bg-green-500 hover:bg-green-600'
                                } text-white text-base md:text-base px-6 py-2.5 rounded-md transition-colors w-full md:w-auto min-w-[150px]`}
                        >
                            <FaCheck className="mr-2" />
                            {isSubmitting ? 'Processing...' : 'Interested'}
                        </button>
                        <button
                            onClick={onRequestClose}
                            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 text-white text-base md:text-base px-6 py-2.5 rounded-md transition-colors w-full md:w-auto min-w-[150px]"
                        >
                            <FaClock className="mr-2" /> Later
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default CampaignDetailsModal;