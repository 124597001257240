import React, { useState } from 'react';
// import img1 from '../assets/images/contact.svg';

export default function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question: '',
    comments: '',
  });

  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    for (const key in formData) {
      if (formData[key].trim() === '') {
        setErrorMsg(`Please fill in all fields.`);
        return false;
      }
    }
  };

  const submitForm = async () => {
    try {
      if (!validateForm()) {
        setErrorMsg('Form validation failed.');
        return;
      }

      const response = await fetch('', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMsg('Form submitted successfully!');
        // console.log(data);
      } else {
        const data = await response.json();
        setErrorMsg(`Error: ${data.error}`);
      }
    } catch (error) {
      setErrorMsg('Network error. Please try again.');
      // console.error(error);
    }
  };

  return (
    <div>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[30px]">
            {/* <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-phone"></i>
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Phone</h5>
                <p className="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>

                <div className="mt-5">
                  <a
                    href="tel:+152534-468-854"
                    className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    +152 534-468-854
                  </a>
                </div>
              </div>
            </div> */}

            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-envelope" />
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Email</h5>
                <p className="text-slate-400 mt-3">
                  The phrasal sequence of the is now so that many campaign and
                  benefit
                </p>

                <div className="mt-5">
                  <a
                    href="mailto:admin@bookmesocial.com"
                    className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    admin@bookmesocial.com
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center px-6 mt-6">
              <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                <i className="uil uil-map-marker" />
              </div>

              <div className="content mt-7">
                <h5 className="title h5 text-xl font-medium">Location</h5>
                <p className="text-slate-400 mt-3">
                  17th,Ramnagar, Madipakkam,Chennai - 600043.
                </p>

                {/* <div className="mt-5">
                  <a
                    href="https://www.google.com/maps/place/Madipakkam,+Chennai,+Tamil+Nadu/@12.9657549,80.1990926,15z/data=!4m6!3m5!1s0x3a525de9463abdc1:0x71f82075882963d!8m2!3d12.9647462!4d80.1960832!16zL20vMDdoNnFq"
                    data-type="iframe"
                    className="video-play-icon read-more lightbox relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out"
                  >
                    View on Google map
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
            {/* <div className="lg:col-span-4 md:col-span-6"> */}
            {/* <img src={img1} alt="" /> */}
            {/* </div> */}

            {/* <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white rounded-md shadow p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-medium">
                    Get in touch !
                  </h3>

                  <form
                    method="post"
                    name="myForm"
                    id="myForm"
                    onsubmit="return validateForm()"
                  >
                    <p className="mb-0" id="error-msg" />
                    <div id="simple-msg" />
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label for="name" className="font-semibold">
                             Name : 
                          </label>
                          <div className="form-icon relative mt-2">

                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-input  w-full py-2 px-3 h-10  rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="Name :"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label for="email" className="font-semibold">
                             Email :
                          </label>
                          <div className="form-icon relative mt-2">

                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-input  w-full py-2 px-3 h-10  rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="Email :"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                        <div className="text-start">
                          <label for="subject" className="font-semibold">
                            Mobile Number :
                          </label>
                          <div className="form-icon relative mt-2">

                            <input
                              name="subject"
                              id="subject"
                              className="form-input  w-full py-2 px-3 h-10  rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="Mobile Number"
                              value={formData.question}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <div className="text-start">
                          <label for="subject" className="font-semibold">
                             Question :
                          </label>
                          <div className="form-icon relative mt-2">

                            <input
                              name="subject"
                              id="subject"
                              className="form-input  w-full py-2 px-3 h-10  rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="Subject :"
                              value={formData.question}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-5">
                        <div className="text-start">
                          <label for="comments" className="font-semibold">
                             Comment :
                          </label>
                          <div className="form-icon relative mt-2">

                            <textarea
                              name="comments"
                              id="comments"
                              className="form-input w-full py-2 px-3 h-28 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                              placeholder="Message :"
                              value={formData.comments}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mb-0" id="error-msg">
                      {errorMsg}
                    </p>
                    <div id="simple-msg">{successMsg}</div>
                    <button
                      type="submit"
                      id="submit"
                      name="send"
                      onClick={submitForm}
                      className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full justify-center flex items-center"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            {/*<iframe
              title="map"
              src="https://www.google.com/maps/d/embed?mid=1P3Dd_ZSHcKWMRDZqRT_KDT_WVtg&ehbc=2E312F" width="640" height="480"
              className="w-full h-[500px]"
              allowfullscreen
            />*/}
          </div>
        </div>
      </div>
    </div>
  );
}





