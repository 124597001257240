import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../constant/constant';
import { UserContext } from '../Auth/UserContext';
import { PuffLoader } from 'react-spinners';
import Cookies from 'js-cookie';
import { FaLock, FaKey, FaPaperPlane, FaCheck } from 'react-icons/fa';

export default function Settings() {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);

    const validateFields = (fields) => {
        for (let field of fields) {
            if (!field) {
                return false;
            }
        }
        return true;
    };

    const handleSendOtp = async () => {
        if (!user?.email_id) {
            toast.error('No user email found', {
                toastId: 'no-email-error'
            });
            return;
        }

        if (!validateFields([oldPassword, newPassword])) {
            toast.error('Please fill in both old and new password', {
                toastId: 'validation-error'
            });
            return;
        }

        try {
            setLoading(true);
            const response = await fetch(`${API_URL}/api/auth/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: user.email_id,
                    type: 'change-password'
                }),
                credentials: 'include'
            });

            const result = await response.json();

            // Dismiss loading toast

            if (response.ok) {
                toast.success('OTP sent successfully!', {
                    toastId: 'otp-success'
                });
                setStep(2);
            } else {
                toast.error(result.error || 'Failed to send OTP', {
                    toastId: 'otp-error'
                });
            }
        } catch (error) {
            toast.error('Error sending OTP', {
                toastId: 'otp-error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChangePassword = async () => {
        if (!user || !user.email_id) {
            toast.error('No user information found.', {
                toastId: 'no-user-error'
            });
            return;
        }

        if (!validateFields([oldPassword, newPassword, otp])) {
            toast.error('Please fill in all fields before changing the password', {
                toastId: 'validation-error'
            });
            return;
        }

        try {
            setLoading(true);
            const response = await fetch(`${API_URL}/api/auth/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    oldPassword,
                    newPassword,
                    otp: otp.toString(),
                    email: user.email_id
                }),
                credentials: 'include'
            });

            const result = await response.json();

            if (response.ok) {
                toast.success('Password changed successfully!', {
                    toastId: 'password-success'
                });
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000);
            } else {
                if (result.error === 'OTP has expired') {
                    toast.error('OTP has expired. Please request a new one.', {
                        toastId: 'otp-expired'
                    });
                    setStep(1);
                } else if (result.error === 'Invalid OTP') {
                    toast.error('Invalid OTP. Please try again.', {
                        toastId: 'invalid-otp'
                    });
                } else if (result.error === 'Incorrect old password') {
                    toast.error('Old password is incorrect.', {
                        toastId: 'incorrect-old-password'
                    });
                } else {
                    toast.error(result.error || 'Failed to change password', {
                        toastId: 'change-password-error'
                    });
                }
            }
        } catch (error) {
            toast.error('Error changing password', {
                toastId: 'change-password-error'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="text-gray-700 bg-indigo-100 min-h-full h-full flex items-center justify-center py-4 md:py-7 px-3 md:px-6">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 md:p-8">
                {step === 1 ? (
                    <div>
                        <h2 className="text-xl md:text-3xl font-bold text-center mb-6 md:mb-8">
                            Change Password
                        </h2>
                        <div className="flex flex-col space-y-4 md:space-y-5">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaLock className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="password"
                                    placeholder="Old Password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    className="w-full py-2.5 md:py-3 pl-10 pr-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                            </div>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaKey className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="w-full py-2.5 md:py-3 pl-10 pr-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                            </div>
                            {loading ? (
                                <div className="flex justify-center py-2">
                                    <PuffLoader color="#4F46E5" size={40} />
                                </div>
                            ) : (
                                <button
                                    onClick={handleSendOtp}
                                    className="w-full bg-indigo-500 text-white py-2.5 md:py-3 px-4 rounded hover:bg-indigo-600 transition-colors duration-200 text-sm md:text-base font-medium flex items-center justify-center gap-2"
                                >
                                    <FaPaperPlane className="h-5 w-5" />
                                    <span>Send OTP</span>
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div>
                        <h2 className="text-xl md:text-3xl font-bold text-center mb-6 md:mb-8">
                            Enter OTP
                        </h2>
                        <div className="flex flex-col space-y-4 md:space-y-5">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FaKey className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    className="w-full py-2.5 md:py-3 pl-10 pr-4 border border-gray-300 rounded focus:outline-none focus:border-indigo-500"
                                />
                            </div>
                            {loading ? (
                                <div className="flex justify-center py-2">
                                    <PuffLoader color="#4F46E5" size={40} />
                                </div>
                            ) : (
                                <button
                                    onClick={handleChangePassword}
                                    className="w-full bg-indigo-500 text-white py-2.5 md:py-3 px-4 rounded hover:bg-indigo-600 transition-colors duration-200 text-sm md:text-base font-medium flex items-center justify-center gap-2"
                                >
                                    <FaCheck className="h-5 w-5" />
                                    <span>Verify OTP and Change Password</span>
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer autoClose={2000} />
        </div>
    );
}
